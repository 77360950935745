import React from "react";
import PropTypes from "prop-types";

const CategoriesTable = ({ categories = [] }) => {
  return (
    <div className="col-md-12 col-lg-8">
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          {categories.length > 0 ? (
            categories.map((category, index) => (
              <tr key={category.id || index}>
                <td>{index + 1}</td>
                <td>
                  <b>{category?.categoryRecords?.[0]?.name || "Unnamed Category"}</b>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="text-center">
                No categories available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

CategoriesTable.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      categoryRecords: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    })
  ),
};

export default CategoriesTable;
