import React, { useEffect } from "react";
import CreateCategory from "./CreateCategory";
import CategoriesTable from "./CategoriesTable";
import { listCategories } from "../../Redux/Actions/CategoryActions";
import { useDispatch, useSelector } from 'react-redux';
import EditCategory from "./EditCategory";

const MainCategories = ({ categoryId }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.categoryList);
  const { createSuccess } = useSelector(state => state.categoryAdd);

  // Merging the useEffects to avoid redundant API calls
  useEffect(() => {
    dispatch(listCategories("AZ"));
  }, [dispatch, createSuccess]);  // Trigger on createSuccess

  // If categoryId exists, render EditCategory, else render CreateCategory
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title txt-primary">Categories</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {categoryId ? (
              <EditCategory categoryId={categoryId} />
            ) : (
              <CreateCategory />
            )}
            {/* Categories table */}
            <CategoriesTable categories={categories} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainCategories;
