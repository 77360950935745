import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { blogDelete } from "../../Redux/Actions/BlogAction";
import moment from "moment";
import { PICTURE_URL } from "../../api/BaseConfig";

const Blog = ({ blog }) => {
  const dispatch = useDispatch();

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(blogDelete(blog.language, id)); // Assuming blog.language exists
    }
  };

  const blogImageUrl = `${PICTURE_URL}${blog.photoUrl}`;
  const blogName = blog?.blogRecords?.name || "Untitled";
  const blogDate = moment(blog?.blogDate?.substring(0, 10)).format("MMMM D, YYYY");

  return (
    <div className="col-md-6 col-sm-6 col-lg-3 mb-5">
      <div className="card card-product-grid shadow-sm">
        <Link to="#" className="img-wrap">
          <img src={blogImageUrl} alt="Blog" className="w-full" />
        </Link>
        <div className="info-wrap">
          <Link to="#" className="title text-truncate">
            {blogName}
          </Link>
          <div className="date mb-2 px-2">
            {blogDate} <span style={{ fontStyle: "italic" }}>published</span>
          </div>
          <div className="row m-2">
            <Link
              to={`/blog/${blog.id}/edit`}
              className="btn btn-sm btn-outline-success p-2 pb-3 col-md-6"
            >
              <i className="fas fa-pen"></i>
            </Link>
            <button
              onClick={() => deleteHandler(blog.id)}
              className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;