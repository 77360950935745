import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { productAdd } from "../../Redux/Actions/ProductActions";
import { PRODUCT_CLEAR } from "../../Redux/Constants/ProductConstants";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";
import MainPanel from "../main-panel/MainPanel";
import Message from "../LoadingError/Error";
import { listCategories } from "../../Redux/Actions/CategoryActions";
import { BASE_URL, PICTURE_URL } from "../../api/BaseConfig";
import axios from "axios";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const AddProductMain = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const languageList = [
    { id: 1, langKey: "AZ" },
    { id: 2, langKey: "GB" },
    { id: 3, langKey: "RU" },
  ];

  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [categoryID, setCategoryID] = useState(null);
  const [photoUrl, setPhotoUrl] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [language, setLanguage] = useState([]);

  const productCreate = useSelector((state) => state.productAdd);
  const { loading, error, product } = productCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  const handleLangChange = useCallback((dataInfo) => {
    const updatedLanguage = {
      name: dataInfo.name,
      description: dataInfo.description,
      languageKey: dataInfo.lang,
    };
    setLanguage((prevLang) => [
      ...prevLang.filter((l) => l.languageKey !== dataInfo.lang),
      updatedLanguage,
    ]);
  }, []);

  useEffect(() => {
    if (product) {
      toast.success("Product Added", ToastObjects);
      dispatch({ type: PRODUCT_CLEAR });
      resetForm();
    }
  }, [product, dispatch]);

  useEffect(() => {
    dispatch(listCategories("AZ"));
  }, [dispatch]);

  const resetForm = () => {
    setPrice(0);
    setDiscount(0);
    setCategoryID(null);
    setPhotoUrl("");
    setIsFeatured(false);
    setLanguage([]);
  };

  const handlePictureUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${BASE_URL}/file`, formData, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setPhotoUrl(response.data);
    } catch (error) {
      alert("Image upload failed. Please try again.");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      productAdd({
        price,
        discount,
        categoryId: Number(categoryID),
        photoUrl,
        ProductRecords: language,
      })
    );
    history.push("/products");
  };

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/products" className="btn btn-danger text-white">
              Go to products
            </Link>
            <h2 className="content-title txt-primary">Add product</h2>
            <button type="submit" className="btn btn-primary">
              Publish now
            </button>
          </div>

          <div className="row mb-4">
            <div className="col-xl-12 col-lg-12">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <MainPanel
                    mainLang={languageList}
                    changeLang={handleLangChange}
                    languageInfo={language}
                  />
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}

                  <div className="mb-4">
                    <label htmlFor="product_categories" className="form-label">
                      Categories
                    </label>
                    <select
                      id="product_categories"
                      value={categoryID || ""}
                      onChange={(e) => setCategoryID(e.target.value)}
                      required
                      className="form-control"
                    >
                      <option value="" disabled>
                        --Select Categories--
                      </option>
                      {categories?.map((category) => (
                        <option
                          key={category.id}
                          value={category.id}
                        >
                          {category.categoryRecords[0]?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      id="product_price"
                      placeholder="Price"
                      className="form-control"
                      value={price}
                      onChange={(e) => setPrice(Number(e.target.value))}
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="product_discount" className="form-label">
                      Discount
                    </label>
                    <input
                      type="number"
                      id="product_discount"
                      placeholder="Discount"
                      className="form-control"
                      value={discount}
                      onChange={(e) => setDiscount(Number(e.target.value))}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="product_featured" className="form-label">
                      Is Featured?
                    </label>
                    <input
                      type="checkbox"
                      id="product_featured"
                      checked={isFeatured}
                      onChange={(e) => setIsFeatured(e.target.checked)}
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Images</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => handlePictureUpload(e.target.files[0])}
                    />
                    {photoUrl && (
                      <img
                        className="col-4 mt-2"
                        src={`${PICTURE_URL}${photoUrl}`}
                        alt="Product"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddProductMain;
