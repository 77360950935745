import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
} from "@chakra-ui/react";
import Editor from "../editor/Editor";

const MainPanel = ({
  mainLang = [],
  changeLang,
  languageInfo = [],
  descriptionShow = false,
}) => {
  const [currentLangData, setCurrentLangData] = useState({
    id: 0,
    name: "",
    description: "",
    lang: "",
  });

  useEffect(() => {
    changeLang(currentLangData);
  }, [currentLangData, changeLang]);

  const handleInputChange = (key, value, langKey) => {
    const languageData = languageInfo.find((l) => l.languageKey === langKey) || {};
    setCurrentLangData((prev) => ({
      ...prev,
      [key]: value,
      lang: langKey,
      id: languageData.id || 0,
      description: languageData.description || "",
    }));
  };

  return (
    <Tabs variant="enclosed">
      <TabList>
        {mainLang.map((l) => (
          <Tab key={l.id}>{l.langKey}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {mainLang.map((lang) => {
          const languageData = languageInfo.find(
            (l) => l.languageKey === lang.langKey
          ) || { id: 0, name: "", description: "" };

          return (
            <TabPanel key={lang.id}>
              <div>
                <label htmlFor={`name-${lang.langKey}`}>Name</label>
                <Input
                  mb={3}
                  id={`name-${lang.langKey}`}
                  type="text"
                  required
                  defaultValue={languageData.name}
                  onChange={(e) =>
                    handleInputChange("name", e.target.value, lang.langKey)
                  }
                />

                {!descriptionShow && (
                  <div>
                    <label htmlFor={`description-${lang.langKey}`}>Description</label>
                    <Editor
                      value={languageData.description}
                      setDescriptionHandle={(value) =>
                        handleInputChange("description", value, lang.langKey)
                      }
                      setIdHandle={(id) =>
                        setCurrentLangData((prev) => ({ ...prev, id }))
                      }
                      setLangHandle={(langKey) =>
                        setCurrentLangData((prev) => ({ ...prev, lang: langKey }))
                      }
                      setNameHandle={(name) =>
                        setCurrentLangData((prev) => ({ ...prev, name }))
                      }
                    />
                  </div>
                )}
              </div>
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default React.memo(MainPanel);
