import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import Blog from "../blogs/Blog";
import ReactPaginate from "react-paginate";
import { listBlogs } from "../../Redux/Actions/BlogAction";

const MainBlogs = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  // Fetch blog list from the Redux store
  const { loading, error, blog = [] } = useSelector((state) => state.blogList);

  // Fetch blog deletion status from the Redux store
  const { error: errorDelete, success: successDelete } = useSelector(
    (state) => state.blogDeleted
  );

  useEffect(() => {
    dispatch(listBlogs(page));
  }, [dispatch, page, successDelete]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title txt-primary">Blogs</h2>
        <div>
          <Link to="/addblog" className="btn btn-primary">
            Create new
          </Link>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto">
              <input
                type="search"
                placeholder="Search..."
                className="form-control p-2"
              />
            </div>
          </div>
        </header>

        <div className="card-body">
          {errorDelete && (
            <Message variant="alert-danger">{errorDelete}</Message>
          )}
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <div className="row">
              {blog.length > 0 ? (
                blog.map((blogItem) => (
                  <Blog blog={blogItem} key={blogItem.id} />
                ))
              ) : (
                <Message variant="alert-info">No blogs found</Message>
              )}
            </div>
          )}

          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={15} // Adjust this dynamically if possible
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </section>
  );
};

export default MainBlogs;
